<template>

<div>

      <v-snackbar
                                      :timeout = "snackbar.timeout"
                                      v-model  = "snackbar.display"
                                      absolute
                                      outlined
                                      rounded = "pill"
                                      color = "primary"
                                      top
                                      >
                                      <v-icon class="mr-2" color="red">mdi-alert-circle-outline</v-icon>
                                      {{snackbar.displayText}}
      </v-snackbar>


      <!-- this engineer account name action-->
      <div class="d-flex align-baseline">
     
          <v-text-field 
                    class="mr-2"
                    ref                   = "customerLookupId"
                    autofocus
                   :loading               = "loading"
                   :rules                 = "customerRules"
                    v-model               = "customer_search_id"
                    label                 = "Enter Customer ID"
                    required
                    solo
                    clearable
                    placeholder     ="Enter Customer ID"
                    @click:clear    ="onClearClicked"
          ></v-text-field>

          <v-btn @click="searchAccounts" color="primary" dark>Find&nbsp;
                <v-progress-circular  v-if  = "isSearchingActive" size=18 right indeterminate></v-progress-circular>
                <v-icon               v-else                      dark right>mdi-account-search</v-icon>
          </v-btn>

      </div>
          

      <!-- this engineer account name action-->
        <v-row dense  style="margin-bottom: 10px;">
              <v-expansion-panels  class= "expansion_panels">
                        <v-expansion-panel  class="expansion_panel"> 
                              <v-expansion-panel-header>
                              <span>
                                  <v-icon left> {{ true  ? 'mdi-eye' : 'mdi-eye-off'}}</v-icon>
                                  My Engineer Account - '{{ engineer.ENGINEER_ID }}'
                              </span>
                              </v-expansion-panel-header>
                        
                              <v-expansion-panel-content>
                                  <engineer-update></engineer-update>
                              </v-expansion-panel-content>
                        </v-expansion-panel> 
              </v-expansion-panels>
        </v-row>
      <!-- -->

      <!-- selected customer action-->
        <v-row dense  style="margin-bottom: 10px;">
          <v-col>
            <v-expansion-panels  
                      :disabled   =  "isCustomerValid  ? false : true"
                      :value      =  "isCustomerValid  ? 0 : null"
                        class      = "expansion_panels">

                      <v-expansion-panel  class="expansion_panel"> 
                      
                              <v-expansion-panel-header>
                              <span>
                                  <v-icon left>{{isCustomerValid  ? 'mdi-eye' : 'mdi-eye-off'}}</v-icon>
                                  Customer Information
                              </span>
                              </v-expansion-panel-header>
                        
                              <v-expansion-panel-content>
                                <v-form>
                                  <v-row no-gutters>
                                    <v-col class="pa-1" cols="12" sm="6">
                                          <v-text-field  readonly dense outlined v-model= "customer.NAME"           label="Customer Name"> </v-text-field>
                                          <v-text-field  readonly dense outlined v-model= "customer.ACCOUNT"        label="Customer ID"> </v-text-field>
                                          <v-textarea    readonly dense outlined v-model= "formatCustomerAddress"   label="Address"></v-textarea>
                                    </v-col>

                                    <v-col class="pa-1" cols="12" sm="6">
                                          <v-text-field  readonly dense outlined v-model= "customer.POSTCODE"      label="Postcode"></v-text-field>
                                          <v-text-field  readonly dense outlined v-model= "customer.PHONEH"        label="Home Phone"></v-text-field>
                                          <v-text-field  readonly dense outlined v-model= "customer.PHONEM"        label="Mobile Phone"></v-text-field>
                                    </v-col>
                                  </v-row>
                                </v-form>
                              </v-expansion-panel-content>
                        
                      </v-expansion-panel> 

            </v-expansion-panels>          
          </v-col>
        </v-row>
      <!-- -->
   
      <!-- engineer Forms -->
        <v-row dense  style="margin-bottom: 10px;">
          <v-col style="margin-bottom: 40px;">
              <v-expansion-panels   :disabled = "isCustomerValid  ? false : true"
                                    :value    = "isCustomerValid  ? 0    : null"
                                    class     = "expansion_panels">
          
                    <v-expansion-panel  class="dl_expansion_panel"> 

                        <v-expansion-panel-header   class="dl_expansion_panel_header">
                            <span>
                                <v-icon left>{{isCustomerValid  ? 'mdi-eye' : 'mdi-eye-off'}}</v-icon>
                              Engineer Forms
                          </span>
                          
                        
                          
                        </v-expansion-panel-header>
                        
                        <v-expansion-panel-content  class="dl_expansion_panel_content">
                          
                          <v-list-item-group  class="mb-2" active-class="pink--text">

                              <template v-for="(item, index) in  engineerItems">
                              
                              
                                    <v-list-item v-if="isThisFormAllowed(item)"
                                                  :disabled=disableFormsListItem(item)
                                                  @click.stop="onItemClicked(item.link)"
                                                  :key=index
                                                  style="display: flex;align-items: baseline;"
                                                  >
                                      
                                       <v-list-item-icon  style = "display:flex;flex-flow:column">
                                         <v-icon class="mdi-36px" color="red darken-3">mdi-file-pdf</v-icon>
                                       </v-list-item-icon>

                                       <v-list-item-content>

                                           <v-list-item-title     v-text="item.title"></v-list-item-title>
                                           <v-list-item-subtitle  v-text="item.headline"></v-list-item-subtitle>
                                           <v-list-item-subtitle  v-text="item.subtitle"></v-list-item-subtitle>

                                           <div v-if="disableFormsListItem(item)">
                                              <v-list-item-subtitle>Not Availible</v-list-item-subtitle>
                                              <v-list-item-subtitle>LastUpload : {{ formsUploadStatus[`${item.formName}`].uploadedTime }}</v-list-item-subtitle>
                                            </div>

                                           
                                       </v-list-item-content>

                                       <v-btn     fab small tile color="success"
                                                  v-if          = "showOverride(item)"
                                                  :disabled     = "disableOverride()"
                                                  @click.stop   = "onItemClicked(item.link)"
                                                  :style        = "[isDisabled ? { 'pointer-events' : 'none' } : {'pointer-events' : 'auto'} ]">

                                                 <v-icon>mdi-account-supervisor-circle-outline</v-icon>
                                       </v-btn>
                                                             
                                    </v-list-item>
           
                                  <v-divider v-if = "index <  engineerItems.length - 1" :key="index+10"
                                  ></v-divider>
                              </template>
                              
                          </v-list-item-group>
                          
                        </v-expansion-panel-content>
                    </v-expansion-panel> 
              </v-expansion-panels>
          </v-col>
        </v-row>
      <!-- -->

</div>

</template>


<script>

  import EngineerUpdate from '../components/EngineerUpdate.vue';
  import {store}        from '../main.js';

  export default {
        name        : 'Engineer',
        created() {

            /////////////////////////////
            // only if isByPass is set //
            /////////////////////////////
            if (this.$route.params.isByPass){
                var customer            = JSON.parse( localStorage.getItem("equalResponseCustomer") );
                this.isCustomerValid    = this.$route.params.isByPass;
                this.customer_search_id = customer.ACCOUNT;
                this.customer           = customer; 
                return;
            } else {
                this.isCustomerValid = false;
                this.customer        = {}; 
            }

            //////////////////////////////////////////////////////////////////
            // do we have a valid customer maybe a new form to be created .?//
            //////////////////////////////////////////////////////////////////
            var customer            = JSON.parse( localStorage.getItem("equalResponseCustomer") );
            if ( customer ) {
                this.customer_search_id = customer.ACCOUNT;
                this.customer           = customer; 
                this.isCustomerValid    = true;
            }
        },
        mounted() {
            this.$nextTick(function () {
                  this.engineer   = JSON.parse( localStorage.getItem("equalResponseEngineer") );
                  store.consoleLog( "Engineer View Mounted");
                  store.consoleLog( this.engineer );
                  this.getFormsUploadStatus();
            })
        }, 
        components  : { EngineerUpdate },
        computed    : {
              
            
            formatCustomerAddress() {
                                  let address =    this.customer.NMBR  + " " +
                                                   this.customer.ROAD  + ", " +
                                                   this.customer.DISTRICT + ", " +
                                                   this.customer.TOWN + ", " +
                                                   this.customer.COUNTY;
                                  return address;                 
            }


        },
        data        : () => ({

              loading              : false,
              formsUploadStatus    : null,
              isSearchingActive    : false,
              snackbar             :  { displayText : "", display : false, timeout : 1000},
              isCustomerValid      : false,
              customer_search_id   : "",
              customer             : {},
              engineer             : {},
              select               : null,
              isDisabled           : true,
              customerRules: [
                  v => !!v || 'Customer ID is required',
                  v => (v && v.length <= 10) || 'Customer ID must be less than 10 characters',
                  v => (v && v.length >  2)  || 'Customer ID be more than 3 characters'

              ],
              engineerItems    : [
                         { "formName" : "enhancedTest",        "title" : "Enhanced Test",             "headline":"Supplementary Checks",              "subtitle":"",                             "link" :"/enhancedTest",         flex: 6, src: 'https://cdn.vuetifyjs.com/images/cards/house.jpg' },
                         { "formName" : "annualInspection",    "title" : "Annual Liquid Inspection",  "headline":"Annual Liquid Inspection form",     "subtitle":"For liquid installations",     "link" :"/annualInspection",     flex: 6, src: 'https://cdn.vuetifyjs.com/images/cards/house.jpg' },
                         { "formName" : "testCertificate",     "title" : "Test Certificate",          "headline":"Bulk Tank Periodic Examination",    "subtitle":"",                             "link" :"/testCertificate",      flex: 6, src: 'https://cdn.vuetifyjs.com/images/cards/house.jpg' },
                         { "formName" : "reBrand",             "title" : "Re Brand",                   "headline":"Re-branding",                      "subtitle":"",                             "link" :"/reBrand",              flex: 6, src: 'https://cdn.vuetifyjs.com/images/cards/house.jpg' },
                         { "formName" : "jobSheet",            "title" : "Job Sheet",                 "headline":"Gas Installation Report" ,          "subtitle":"Form & Emergency Call",        "link" :"/jobsheet",             flex: 6, src: 'https://cdn.vuetifyjs.com/images/cards/house.jpg' },
                         { "formName" : "selfOwnedCommercial", "title" : "Self Owned Commercial",     "headline":"Tank Survey Form Vessel Details" ,  "subtitle":"",                             "link" :"/selfOwnedCommercial",  flex: 6, src: 'https://cdn.vuetifyjs.com/images/cards/house.jpg' },
                         { "formName" : "meters",              "title" : "Meter Reading",             "headline":"Customer Meter Reads" ,             "subtitle":"",                             "link" :"/meterRead",               flex: 6, src: 'https://cdn.vuetifyjs.com/images/cards/house.jpg' },
                         
              ],
              checkbox: false,
              result :'',
              alert  : false,
      }),
        methods     : {

              // only show the override button if the list item is for a rebrand
              showOverride( item ) {
                            
                            let result = false;                       
                            if ( item.formName == undefined)
                                  result = false;
                            else if ( item.formName.toLowerCase() == 'rebrand')
                                 result =  true;
                                                               
                            return result;
            
              },

              // disable the override button is  the account is restricted
              disableOverride() {

                let result = false;
       
                if ( this.engineer['FORMS']) {
                   let theCat = this.engineer['FORMS'].find( (animal) => animal.value.toLowerCase() === "restrictoverride");
                   console.log("is restricted: " , theCat);
                   if ( theCat === undefined) {
                        result = false;
                        this.isDisabled = false;
                   }
                   else {
                        result = true;
                        this.isDisabled = true;
                      }      
                }

                return (result);
       
              },

              isThisFormAllowed( item ) {
                   return ( store.isFormAllowed( item.formName ) );
              },
              disableFormsListItem( item ) {
          
                 if ( this.formsUploadStatus === null ) { return false };
                 if (item.formName.toLowerCase() === "rebrand")
                     return this.formsUploadStatus[`${item.formName}`] ? true : false; 
                 else
                    return false;
                   
              },
              getFormsUploadStatus() {
                  
                  store.consoleLog("getFormsUploadStatus()");

                  if ( !this.isCustomerValid ) return;        // only if we have a valid customer
                
                  const formsUploadStatusURL  =  this.$restServer.formsUploadStatusURL;
                  const account               =  this.customer['ACCOUNT'].toLowerCase();
                  const engineer              =  this.engineer['ENGINEER_ID'].toLowerCase();
                  this.$http.post( formsUploadStatusURL,  {"account" : account, "engineer" : engineer })
                  .then(  (response) => { 
                        store.saveFormsUploadStatus( response.data )
                        this.formsUploadStatus = response.data; 
                  })
                  .catch( (e)        => { store.consoleLog(e); });
              },
              showSnackbar( text ) {
                        this.snackbar.displayText = text;
                        this.snackbar.display     = true;
              },
              onItemClicked    : function(itemLink) {
                 this.$router.push(itemLink);
              },
              onClearClicked  : function() {
                  this.isCustomerValid   = false;
                  this.allFormsUploadStatus
              },
              searchAccounts : function() {
  
                  if ( this.customer_search_id === null) {
                        this.$refs["customerLookupId"].focus();
                        return; 
                  }

                  if ( this.customer_search_id.length < 2){ 
                       this.$refs["customerLookupId"].focus();
                       return; 
                  }
            
                  try {
                          const url     = this.$restServer.searchURL + "/" + this.customer_search_id;

                          let   self              = this;
                          self.isCustomerValid    = false;
                          self.isSearchingActive  = true;
                          this.loading            = true;  

                          this.$http.get(url)
                          .then(  (response) => {

                              self.isSearchingActive  = false;
                              this.loading            = false;  

                              if ( response.data.RECORD_FOUND === 'TRUE' ) {
                                    localStorage.setItem("equalResponseCustomer", JSON.stringify(response.data) );
                                    self.customer          = JSON.parse( localStorage.getItem("equalResponseCustomer") );
                                    self.isCustomerValid   = true;

                                    // update the forms status for this customer
                                    self.getFormsUploadStatus();


                              } else {
                                   localStorage.removeItem("equalResponseCustomer");
                                   self.customer          = {};
                                   self.isCustomerValid   = false;
                                   const   errorMsg = (response.data.faultstring) ? response.data.faultstring : `'${response.data['REQUEST_SEEKVALUE']}' - Record Not Found`;
                                   throw ( errorMsg);
                              }
                          })
                          .catch( (error) => {
                                 console.log(error);
                                 self.isCustomerValid    = false;
                                 self.isSearchingActive  = false;
                                 this.loading            = false;  
                                 this.$refs["customerLookupId"].focus();
                         
                                 self.customer           = {};
                                 self.showSnackbar(error);
                           });
                  } catch (error) {
                           console.log(error);
                           self.isCustomerValid   = false;
                           self.isSearchingActive  = false;
                           this.loading            = false;  
                           this.$refs["customerLookupId"].focus();
                         
                           self.customer          = {};
                           self.showSnackbar(error);
                  }
              }
      }
  }
</script>

<style scoped> 
  @import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap');

  .form_labels {
    font-family: 'Source Sans Pro', sans-serif;
    font-size:    16px;
    font-weight:   700;
   }

  .anchors {
    text-decoration: none;
    font-weight:   400;
  }
</style>